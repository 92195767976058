import cx from 'classnames'

import Button from '../Button'

import useTranslations from '../../hooks/useTranslations'
import useSmoothScroll from '../../hooks/useSmoothScroll'

import { StepUp1, StepUp2, StepUp5 } from '../../constants'

const Hero = () => {
  const t = useTranslations()

  const onClick = useSmoothScroll()

  // SEE index.css for the definition of .content-area
  const classNames = cx(
    'p-4',
    'sm:p-8',
    'md:p-16',
    'lg:p-24',
    'content-area',
    'bg-white',
    'flex',
    'flex-col',
    'items-center',
    'space-y-8',
    'justify-center',
  )

  return (
    <section className={classNames}>
      <h1 className={`font-serif ${StepUp5}`}>{t('brand:name')}</h1>
      <h2 className={`mx-4 text-gray-900 text-center ${StepUp2}`}>
        {t('brand:memo')}
      </h2>
      <ul
        className={`flex items-center space-x-4 sm:space-x-6 md:space-x-7 text-gray-900 ${StepUp1}`}>
        <li>{t('brand:modality:face-to-face')}</li>
        <li className="xs:text-sm">✦</li>
        <li>{t('brand:modality:by-phone')}</li>
        <li className="xs:text-sm">✦</li>
        <li>{t('brand:modality:online')}</li>
      </ul>
      <Button data-hash="#contact" onClick={onClick}>
        {t('hero:cta')}
      </Button>
    </section>
  )
}

export default Hero
