const MOBILE_DEVICE_RE =
  /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera mini|avantgo|mobilesafari|docomo)/i
const TABLET_DEVICE_RE =
  /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/i

const isMobile = () => MOBILE_DEVICE_RE.test(navigator.userAgent)
const isTablet = () => TABLET_DEVICE_RE.test(navigator.userAgent)
export const isMobileOrTablet = () => isMobile() || isTablet()

/**
 * buildWhatsappUrl - Generate URL to be used in whatsapp links, i.e. links that
 * will open a whatsapp conversation with the given number and, optionally, a
 * predefined message
 * SEE https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/
 * NOTE the following URLs are equivalent
 * https://wa.me/<phone>?text=<text>
 * https://api.whatsapp/send?phone=<phone>&text=<text>
 * @param {String} phone - International phone number without zeros or symbols
 * (parentheses, whitespaces, hyphens...)
 * @param {String} text - Predefined text message
 * @return {String} Whatsapp URL, ready to be used in an anchor's href property
 */
export const buildWhatsappUrl = (phone, text) => {
  const target = isMobileOrTablet() ? 'api' : 'web'
  let url = `https://${target}.whatsapp.com/send?phone=${phone}`
  if (text) url += `&text=${text}`
  return url
}

/**
 * setVhCssVariable - set css variable similar to 1vh but keeping in mind the
 * browser's navbar in mobile devices
 * SEE https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export const setVhCssVariable = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
