import { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Logo from '../Logo'
import Nav from '../Nav'
import Button from '../Button'
import LanguageSelector from '../LanguageSelector'

import { ReactComponent as MenuIcon } from '../../assets/icons/icon-menu.svg'

import useTranslations from '../../hooks/useTranslations'
import useSmoothScroll from '../../hooks/useSmoothScroll'

// TODO change at certain scroll point
// REVIEW use height instead of padding for the header?
const Header = props => {
  const { onMenuClick } = props
  const t = useTranslations()
  const ref = useRef()
  const [shadow, setShadow] = useState(false)
  const [shrink, setShrink] = useState(props.shrink)

  // NOTE enable switching variants in storybook
  useEffect(() => setShrink(props.shrink), [props.shrink])

  const onScroll = useCallback(() => {
    if (ref.current) setShadow(ref.current.getBoundingClientRect().top === 0)
    // NOTE use different thresholds for shrinking and unshrinking to avoid
    // getting stuck in between
    if (shrink && window.scrollY < window.innerHeight * 0.25) {
      setShrink(false)
    } else if (window.scrollY > window.innerHeight * 0.75) {
      setShrink(true)
    }
  }, [shrink])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  const onClick = useSmoothScroll()

  const className = cx(
    'px-6',
    'md:px-8',
    'lg:px-12',
    'py-3',
    shrink ? 'md:py-5' : 'md:py-8',
    'bg-white',
    'sticky',
    'top-0',
    // shrink && 'shadow-header',
    // 'shadow-header',
    shadow && 'shadow-header',
    'transition-header',
    'duration-300',
    shrink && 'md:mb-6', // NOTE compensate difference in size
  )

  const logoClassName = cx(
    'hidden',
    'lg:block',
    'overflow-hidden',
    shrink ? 'w-32' : 'w-0',
    // shrink && 'mr-12',
    // shrink ? 'w-22' : 'w-0',
    // 'transition-logo',
    'transition-width',
    'duration-300',
  )

  const buttonClassName = cx(
    !shrink && 'opacity-0 pointer-events-none',
    'transition-opacity',
    'duration-300',
  )

  return (
    <header className={className} ref={ref}>
      <div className="flex items-center">
        <MenuIcon
          className="md:hidden"
          aria-label={t('action:open-menu')}
          onClick={onMenuClick}
        />
        <Logo className={logoClassName} />
        <Nav className="hidden md:block" />
        <div className="flex flex-1 items-center justify-end">
          <Button
            variant={Button.variants.SLIM}
            className={buttonClassName}
            data-hash="#contact"
            onClick={onClick}>
            {t('header:cta')}
          </Button>
          <LanguageSelector className="ml-6 hidden md:flex" />
        </div>
      </div>
    </header>
  )
}

Header.heights = { DEFAULT: 106, SLIM: 82 }

Header.propTypes = {
  shrink: PropTypes.bool,
  onMenuClick: PropTypes.func.isRequired,
}

export default Header
