// eslint-disable-next-line import/no-anonymous-default-export
export default `
### Sobre mi

El meu nom és María Giner Samper i sóc psicòloga col·legiada amb número CV16208. Vaig estudiar psicologia en la [Universitat Oberta de Catalunya (UOC)](https://www.uoc.edu/portal/en/index.html) i des que vaig acabar la carrera universitària he continuat formant-me amb la finalitat d'ampliar la mirada i així, proporcionar una atenció integral en l'àmbit de la psicologia.

D'aquesta formació addicional emfatitze l'especialització en acompanyament en processos de pèrdua i duel en el [Institut IPIR](http://www.ipirduelo.com/), l'especialització en clínica psicoanalítica, mitjançant el Màster Internacional en Salut Mental - Clínica Psicoanalítica, que imparteix [l'Institut d'Alts Estudis Universitaris (IAEU)](https://www.iaeu.edu.es/inicio/), i la formació continuada en la [Escola Lacaniana de Psicoanàlisi (ELP)](https://elp-cvalenciana.org/) d'Alacant.

Des del 2019 col·labore en el departament de psicologia del [Telèfon de l'Esperança](https://telefonodelaesperanza.org/) atenent de manera especialitzada i urgent a persones en situació de crisi. Aquesta ocupació em permet anar aprofundint en l'experiència d'acompanyar i atendre situacions de tota índole i gravetat.

Integrar els coneixements adquirits, d'una banda, i l'experiència professional, d'una altra, em possibilita proposar un abordatge terapèutic emmarcat dins d'un model propi elaborat a partir de diferents metodologies i perspectives.
`
