import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as WhatsappIcon } from '../../assets/icons/icon-whatsapp.svg'
import { ReactComponent as PhoneIcon } from '../../assets/icons/icon-phone.svg'
import { ReactComponent as MailIcon } from '../../assets/icons/icon-email.svg'

import useTranslations from '../../hooks/useTranslations'
import { buildWhatsappUrl } from '../../lib/utils'

const ContactItem = props => {
  const t = useTranslations()
  const { href, icon: Icon, channel, contactInfo, contactDetails } = props

  const className = cx(
    'rounded-lg',
    'shadow-card',
    'border',
    'border-transparent',
    'hover:bg-gray-200',
    'hover:border-secondary',
    'transition-colors',
  )

  return (
    <li className={className}>
      <a
        className="px-4 pt-10 pb-7 flex flex-col items-center"
        href={href}
        target="_blank"
        rel="noreferrer">
        <Icon className="mb-4 w-16 h-16" />
        <dl className="flex flex-col items-center text-center">
          <dt className="sr-only">{t('noun:channel')}</dt>
          <dd className="font-semibold">{channel}</dd>
          <dt className="sr-only">{t('noun:contact-info')}</dt>
          <dd className="text-gray-900 text-sm">{contactInfo}</dd>
        </dl>
        <span className="mt-1.5 text-gray-900 text-sm">✦</span>
        <dl className="mt-1.5 flex flex-col items-center text-center">
          <dt className="sr-only">{t('noun:contact-details')}</dt>
          <dd className="text-gray-900 text-sm">{contactDetails}</dd>
        </dl>
      </a>
    </li>
  )
}

ContactItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  channel: PropTypes.string.isRequired,
  contactInfo: PropTypes.node.isRequired,
  contactDetails: PropTypes.string.isRequired,
}

const Contact = props => {
  const { className } = props
  const t = useTranslations()

  const classNames = cx(
    'grid',
    'grid-cols-1',
    'gap-6',
    'sm:grid-cols-2',
    'md:grid-cols-3',
    className,
  )

  return (
    <ul className={classNames}>
      <ContactItem
        href={buildWhatsappUrl(t('brand:whatsapp'))}
        icon={WhatsappIcon}
        channel={t('noun:whatsapp')}
        contactInfo={t('brand:phone')}
        contactDetails={t('brand:whatsapp:contact-details')}
      />
      <ContactItem
        href={`tel:${t('brand:phone')}`}
        icon={PhoneIcon}
        channel={t('noun:phone')}
        contactInfo={t('brand:phone')}
        contactDetails={t('brand:phone:contact-details')}
      />
      <ContactItem
        href={`mailto:${t('brand:email')}`}
        icon={MailIcon}
        channel={t('noun:email')}
        contactInfo={t('brand:email')}
        contactDetails={t('brand:email:contact-details')}
      />
    </ul>
  )
}

Contact.propTypes = {
  className: PropTypes.string,
}

export default Contact
