import PropTypes from 'prop-types'
import cx from 'classnames'

import { Baseline } from '../../constants'

const Paragraph = props => {
  const { children } = props
  const classNames = cx('mb-2', 'text-gray-900', Baseline)
  return <p className={classNames}>{children}</p>
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Paragraph
