import PropTypes from 'prop-types'
import cx from 'classnames'

const Button = props => {
  const { variant, className, ...rest } = props
  const classNames = cx(
    'inline-flex',
    'items-center',
    'bg-accent',
    'hover:bg-accent-200',
    'active:bg-accent-300',
    'text-white',
    'font-semibold',
    variant === Button.variants.SLIM ? 'px-6' : 'px-8',
    variant === Button.variants.SLIM ? 'py-2' : 'py-4.5',
    'rounded-full',
    'transition-colors',
    className, // REVIEW is this really necessary?
  )
  return <button {...rest} className={classNames} />
}

Button.variants = {
  DEFAULT: 'default',
  SLIM: 'slim',
}

Button.defaultProps = {
  variant: Button.variants.DEFAULT,
  type: 'button',
}

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(Button.variants)),
  onClick: PropTypes.func, // .isRequired
  children: PropTypes.node.isRequired,
  // HTML button tag properties
  type: PropTypes.string,
  className: PropTypes.string,
}

export default Button
