import { useCallback } from 'react'

// NOTE header height (slim or mobile) + some offset
const OFFSET_DESKTOP = 80 + 24
const OFFSET_MOBILE = 64 + 24

// REVIEW does not work properly when we are close to the top of the component,
// because the header height is different
const useSmoothScroll = callback => {
  const handleClick = useCallback(
    ev => {
      ev.preventDefault()
      const target = document.querySelector(
        ev.target.hash || ev.target.dataset.hash,
      )
      // NOTE md breakpoint, see tailwind.config.js
      const offset = window.innerWidth < 900 ? OFFSET_MOBILE : OFFSET_DESKTOP
      const top = target.offsetTop - offset
      window.scrollTo({ top, behavior: 'smooth' })

      if (callback) callback(ev)
    },
    [callback],
  )

  return handleClick
}

export default useSmoothScroll
