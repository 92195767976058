import { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useTranslations from '../../hooks/useTranslations'
import useSmoothScroll from '../../hooks/useSmoothScroll'

// SEE https://css-tricks.com/sticky-table-of-contents-with-scrolling-active-states/
// NOTE a polyfill for IntersectionObserver can be found in the following link:
// https://github.com/w3c/IntersectionObserver

const NavItem = props => {
  const { active, children, href, onClick } = props
  const className = cx(
    !active && 'hover:text-gray-800',
    'py-2',
    'border-b-2',
    active ? 'border-black' : 'border-transparent',
  )
  // IDEA this could be written as <a {...children} className={className} />
  // although it generates linter warnings
  return (
    <a href={href} className={className} onClick={onClick}>
      {children}
    </a>
  )
}

NavItem.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const INIT = { about: false, project: false, modality: false, contact: false }

const Nav = props => {
  const { className } = props
  const t = useTranslations()

  const handleClick = useSmoothScroll()

  const visibility = useRef(INIT)
  const [, forceUpdate] = useState()

  useEffect(() => {
    // NOTE use a private callback because it has to be passed to the observer
    // constructor, using useCallback here would cause an infinite loop
    const callback = entries => {
      visibility.current = entries.reduce((acc, entry) => {
        acc[entry.target.id] = entry.isIntersecting
        return acc
      }, visibility.current)

      forceUpdate(Math.random())
    }

    const observer = new IntersectionObserver(callback, { threshold: 0.25 })

    document.querySelectorAll('section[id]').forEach(section => {
      observer.observe(section)
    })

    return () => observer.disconnect()
  }, [forceUpdate])

  const classNames = cx('flex', 'space-x-8', className)

  return (
    <nav className={classNames}>
      <NavItem
        active={visibility.current.about}
        href="#about"
        onClick={handleClick}>
        {t('section:about:title')}
      </NavItem>
      <NavItem
        active={visibility.current.project}
        href="#project"
        onClick={handleClick}>
        {t('section:project:title')}
      </NavItem>
      <NavItem
        active={visibility.current.modality}
        href="#modality"
        onClick={handleClick}>
        {t('section:modality:title')}
      </NavItem>
      {/*
      <NavItem
        active={visibility.current.contact}
        href="#contact"
        onClick={handleClick}>
        {t('section:contact:title')}
      </NavItem>
      */}
    </nav>
  )
}

Nav.propTypes = {
  className: PropTypes.string,
}

export default Nav
