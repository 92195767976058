const req = require.context('../config/locales/', true, /.json$/)

// REVIEW find a better approach to this
const contents = {
  es: {
    'section:about:content': require('../content/es/about').default,
    'section:project:content': require('../content/es/project').default,
    'section:modality:content': require('../content/es/modality').default,
  },
  ca: {
    'section:about:content': require('../content/ca/about').default,
    'section:project:content': require('../content/ca/project').default,
    'section:modality:content': require('../content/ca/modality').default,
  },
}

const LANGUAGE_CODE_REGEXP = /^\.\/(\w{2})\.json$/

const locales = req.keys().reduce((acc, filename) => {
  const [, code] = filename.match(LANGUAGE_CODE_REGEXP)
  const texts = req(filename)
  return Object.assign(acc, { [code]: { ...texts, ...contents[code] } })
}, {})

export const SUPPORTED_LOCALES = Object.keys(locales)
// NOTE default locale hardcoded to spanish (ES)
export const DEFAULT_LOCALE = 'es'

/**
 * findMatchingLocale - Finds the best match for a users preferred language
 * based on navigator configuration. If no match is found, a default locale
 * is provided
 * @return {String} - The code for the best locale match for the user, or the
 * default locale if no match is found
 */
export const findMatchingLocale = () =>
  navigator.languages.find(language => SUPPORTED_LOCALES.includes(language)) ??
  DEFAULT_LOCALE

export default locales
