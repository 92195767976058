import { createContext } from 'react'

import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../lib/i18n'

const IntlContext = createContext({
  locales: SUPPORTED_LOCALES,
  locale: DEFAULT_LOCALE,
  setLocale: () => void 0,
})

export default IntlContext
