// eslint-disable-next-line import/no-anonymous-default-export
export default `
### Sobre el proyecto

El compromiso y la rigurosidad personal de este proyecto profesional se sustenta en la formación continuada, la integración del conocimiento adquirido, así como en la profundización de técnicas y la puesta al día en investigación.

> “Jo sóc això que miro i se m'escapa.”

*“Marges”* (márgenes) trae por su significación, y de una parte, ese espacio en blanco, vacío, alrededor de lo escrito, y que actúa como marco, dispuesto para acoger y recoger lo nombrado; de otra, como tiempo necesario y habilitante para la toma de decisiones y la actuación; finalmente, señala lo limítrofe, la frontera que separa, a modo de fisura o brecha, y que también junta o forja una unión entre territorios de distinta naturaleza; reconocer esta frontera permite traer y acoger aquello difícil o imposible de nombrar, aquello que, siendo familiar e íntimo, resulta al mismo tiempo, extraño, desconcertante y ajeno.

#### Una mirada personal hacia lo humano

Desde estas conceptualizaciones hago una composición particular de lo que, a mi parecer, conmueve, concierne y toca al sujeto. Con esto, traigo una propuesta de trabajo terapéutico, que pretende poner en valor la experiencia subjetiva, frente a otros planteamientos terapéuticos, para usarla como brújula.

Esta línea de trabajo inicia su recorrido desplegando los interrogantes y las supuestas certezas que nos han conducido al punto en el que nos encontramos (lo que llamaremos el “instante de ver”). Desde aquí, tomarnos el tiempo necesario para percibir y vislumbrar lo que está puesto en juego (el “instante de comprender”), con el propósito último de aceptarlo y accionar sobre él de una forma más íntegra (el “instante de actuar”).

Así, este espacio terapéutico, pretende ser un lugar de apoyo y acompañamiento en la elaboración de procesos evolutivos, situaciones de pérdidas, duelos y crisis vitales; así como en la gestión de situaciones que generen ansiedad, depresión, angustia y malestar en general; también en circunstancias en las que se desee procurar un cuidado del sí, en la vida joven y adulta.


Por tanto, se pone a disposición de quien lo solicite un soporte que fomente la reflexividad y la coherencia interna, con el fin de habilitar y sostener un espacio interior, no alienado, sin dejar de lado el entorno social en el que se habita.
`
