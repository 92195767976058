// eslint-disable-next-line import/no-anonymous-default-export
export default `
### Sobre el projecte

El compromís i la rigorositat personal d'aquest projecte professional se sustenta en la formació continuada, la integració del coneixement adquirit, així com en l'aprofundiment de tècniques i la posada al dia en recerca.

> “Jo sóc això que mire i se m'escapa.”

“Marges” porta per la seua significació, i d'una part, aquest espai en blanc, buit, al voltant de l'escrit, i que actua com a marc, disposat per a acollir i recollir allò que és nomenat; d'una altra, com a temps necessari i habilitant per a la presa de decisions i l'actuació; finalment, assenyala el límit, la frontera que separa, a manera de fissura o bretxa, i que també junta o forja una unió entre territoris de diferent naturalesa; reconèixer aquesta frontera permet portar i acollir el fet difícil o impossible de nomenar, allò que, sent familiar i íntim, resulta al mateix temps, estrany, desconcertant i aliè.

#### Una mirada personal cap a allò que és humà

Des d'aquestes conceptualitzacions faig una composició particular del que, a parer meu, commou, concerneix i toca al subjecte. Amb això, porte una proposta de treball terapèutic, que pretén fer valdre l'experiència subjectiva, enfront d'altres plantejaments terapèutics, per a usar-la com a brúixola.

Aquesta línia de treball inicia el seu recorregut desplegant els interrogants i les suposades certeses que ens han conduït al punt en el qual ens trobem (el que anomenarem “l'instant de veure”). Des d'ací, prendre'ns el temps necessari per a percebre i albirar el que està posat en joc (“l'instant de comprendre”), amb el propòsit últim d'acceptar-lo i accionar sobre ell d'una forma més íntegra (“l'instant d'actuar”).

Així, aquest espai terapèutic, pretén ser un lloc de suport i acompanyament en l'elaboració de processos evolutius, situacions de pèrdues, duels i crisis vitals; així com en la gestió de situacions que generen ansietat, depressió, angoixa i malestar en general; també en circumstàncies en les quals es desitge procurar una cura del sí, en la vida jove i adulta.

Per tant, es posa a la disposició de qui ho sol·licite un suport que fomente la reflexivitat i la coherència interna, amb la finalitat d'habilitar i sostenir un espai interior, no alienat, sense deixar de costat l'entorn social en el qual s'habita.
`
