import { useContext } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg'

import LocaleContext from '../../Intl/Context'

import useTranslations from '../../hooks/useTranslations'
import useSmoothScroll from '../../hooks/useSmoothScroll'

import { spacing } from '../../helpers'

const MenuItem = props => {
  const { children, href, onClick } = props
  const className = cx(
    'px-6',
    'py-4',
    'block',
    'border-t',
    'border-b',
    'border-white-10',
    'hover:bg-white-10',
    'active:bg-white',
    'active:text-secondary',
    'font-serif',
    'text-white',
    'text-2xl',
  )
  return (
    <a href={href} className={className} onClick={onClick}>
      {children}
    </a>
  )
}

MenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const Menu = props => {
  const { open, onClose } = props
  const { locales, locale: current, setLocale } = useContext(LocaleContext)
  const t = useTranslations()

  const onClick = useSmoothScroll(onClose)

  const className = cx(
    'z-50',
    'transform',
    !open && '-translate-x-full',
    'transition-transform',
    'duration-700',
    'w-full',
    'h-full',
    spacing('-m'), // compensate for main layout margin
    'fixed',
    'bg-gradient-main',
    // REVIEW switch gradient colors?
    // 'bg-gradient-to-br',
    // 'from-secondary',
    // 'to-primary',
    'shadow-header',
  )
  return (
    <aside className={className}>
      <div className="flex items-center justify-end">
        <CloseIcon
          className="m-6"
          aria-label={t('action:close-menu')}
          onClick={onClose}
        />
      </div>
      <div className="flex flex-col border-t border-b border-white-10">
        <MenuItem href="#root" onClick={onClick}>
          {t('section:home:title')}
        </MenuItem>
        <MenuItem href="#about" onClick={onClick}>
          {t('section:about:title')}
        </MenuItem>
        <MenuItem href="#project" onClick={onClick}>
          {t('section:project:title')}
        </MenuItem>
        <MenuItem href="#modality" onClick={onClick}>
          {t('section:modality:title')}
        </MenuItem>
        <MenuItem href="#contact" onClick={onClick}>
          {t('section:contact:title')}
        </MenuItem>
      </div>
      <div className="ml-6 mt-5">
        <label className="text-white-80 text-sm">{t('noun:lang')}</label>
        <ul
          className="mt-1 flex space-x-2"
          aria-label={t('action:select-language')}>
          {locales.map(locale => (
            <li key={locale}>
              <button
                className={cx(
                  'uppercase',
                  locale === current
                    ? 'text-semibold text-white'
                    : 'text-white-60',
                )}
                onClick={() => setLocale(locale)}>
                {t(`lang:${locale}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

Menu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default Menu
