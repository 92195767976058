import PropTypes from 'prop-types'
import cx from 'classnames'

import { StepUp3 } from '../../constants'

const Blockquote = props => {
  const { children, author, book, className } = props

  return (
    <figure className={cx('text-right', className)}>
      <blockquote className={`font-serif text-primary ${StepUp3}`}>
        {children}
      </blockquote>
      <figcaption className="mt-2 text-gray-900 text-sm">
        {author}. <cite>{book}</cite>
      </figcaption>
    </figure>
  )
}

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
  author: PropTypes.string.isRequired,
  book: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Blockquote
