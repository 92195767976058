import React from 'react'
import ReactDOM from 'react-dom'
import Intl from './Intl'
import { polyfill } from 'seamless-scroll-polyfill'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { setVhCssVariable } from './lib/utils'

polyfill()

// NOTE make sure the landing frame is always visible on mobile devices
setVhCssVariable()
// REVIEW onorientationchange is deprecated, although still supported by most
// mobile browsers
// SEE https://developer.mozilla.org/en-US/docs/Web/API/Window/orientationchange_event
window.addEventListener('orientationchange', setVhCssVariable)
// SEE https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Managing_screen_orientation
// SEE https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/onchange
// SEE https://developer.mozilla.org/en-US/docs/Web/Events/Detecting_device_orientation
// SEE https://developer.mozilla.org/en-US/docs/Web/API/Window/deviceorientation_event

ReactDOM.render(
  <React.StrictMode>
    <Intl>
      <App />
    </Intl>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
