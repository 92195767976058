import PropTypes from 'prop-types'
import cx from 'classnames'

// NOTE the following tailwind classes are used
// container, turn each section into a container
// mx-auto, center horizontally
// max-w-5xl, max width (also overrides default prose max width)
// NOTE the following tailwind classes can be provided from parent components
// prose, apply basic text styling
const Section = props => {
  const { className } = props
  const classNames = cx('', className)
  return <section {...props} className={classNames} />
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  // HTML tag properties
  id: PropTypes.string,
  className: PropTypes.string,
}

export default Section
