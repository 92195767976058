import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import heading from '../Heading'
import paragraph from '../Paragraph'
import link from '../Link'
import Blockquote from '../Blockquote'

import useTranslations from '../../hooks/useTranslations'

const HardcodedBlockQuote = () => {
  const t = useTranslations()
  return (
    <Blockquote
      className="my-6 md:my-10"
      author={t('quote:author')}
      book={t('quote:book')}>
      <p>{t('quote')}</p>
    </Blockquote>
  )
}

const renderers = {
  heading,
  paragraph,
  link,
  linkReference: link,
  // REVIEW quote is hardcoded, luckily we only have one for now
  blockquote: HardcodedBlockQuote,
  // break
  // code
  // definition
  // delete
  // emphasis
  // html
  // image
  // imageReference
  // inlineCode
  // list
  // listItem
  // parsedHtml
  // root
  // strong
  // table
  // tableBody
  // tableCell
  // tableHead
  // tableRow
  // text
  // thematicBreak
}

const Markdown = props => {
  const { children } = props
  return <ReactMarkdown renderers={renderers}>{children}</ReactMarkdown>
}

Markdown.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Markdown
