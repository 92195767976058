import { useContext } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import LocaleContext from '../../Intl/Context'
import useTranslations from '../../hooks/useTranslations'

const LanguageItem = props => {
  const { active, ...rest } = props
  const className = cx(
    'text-sm',
    'tracking-wider',
    'hover:text-black',
    active ? 'text-black' : 'text-gray-700',
    active && 'font-semibold',
  )
  return <button {...rest} className={className} />
}

LanguageItem.propTypes = {
  active: PropTypes.bool,
}

const LanguageSelector = props => {
  const { className } = props
  const { locales, locale: current, setLocale } = useContext(LocaleContext)
  const t = useTranslations()
  return (
    <ul
      className={cx('flex', 'space-x-2', className)}
      aria-label={t('action:select-language')}>
      {locales.map(locale => (
        <li key={locale}>
          <LanguageItem
            active={locale === current}
            aria-label={t(`lang:${locale}`)}
            onClick={() => setLocale(locale)}>
            {t(`lang:code:${locale}`)}
          </LanguageItem>
        </li>
      ))}
    </ul>
  )
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
}

export default LanguageSelector
