import { useState } from 'react'

import Header from './components/Header'
import Menu from './components/Menu'
import Hero from './components/Hero'
import Section from './components/Section'
import Markdown from './components/Markdown'
import Heading from './components/Heading'
import Paragraph from './components/Paragraph'
// import Blockquote from './components/Blockquote'
import Contact from './components/Contact'
import Link from './components/Link'

import useTranslations from './hooks/useTranslations'

import { Baseline, StepUp3 } from './constants'
import { spacing } from './helpers'

const Container = props => <div className="max-w-2xl mx-auto" {...props} />

const App = () => {
  const [open, setOpen] = useState(false)
  const t = useTranslations()
  // bg-gradient-to-br from-secondary to-primary
  return (
    <main className={`${spacing('p')} bg-gradient-main`}>
      <Menu open={open} onClose={() => setOpen(false)} />
      <Header onMenuClick={() => setOpen(true)} />
      <Hero />
      <div
        className={`${spacing(
          'mt',
        )} bg-white px-4 sm:px-8 md:px-16 lg:px-24 xl:px-64 pt-4 pb-6 md:pb-10`}>
        <Container>
          <Section id="about">
            <Markdown>{t('section:about:content')}</Markdown>
          </Section>
          <Section id="project">
            <Markdown>{t('section:project:content')}</Markdown>
          </Section>
          <Section id="modality">
            <Markdown>{t('section:modality:content')}</Markdown>
          </Section>
        </Container>
        <Section id="contact">
          <Container>
            <Heading>{t('section:contact:title')}</Heading>
            <Paragraph>{t('contact:cta')}</Paragraph>
          </Container>
          <Contact className="mb-6 md:mt-10 mb-6 md:mb-10" />
          <Container>
            <Paragraph>{t('address:cta')}</Paragraph>
            <Link href={t('map:href')}>
              {`${t('brand:address')} ${t('brand:address:zipCode')}`}
            </Link>
          </Container>
        </Section>
      </div>
      <iframe
        title={t('map:title')}
        src={t('map:src')}
        className="w-full h-96 border-0"
        allowFullScreen=""
        loading="lazy"></iframe>
      <footer className="h-40 sm:h48 md:h-56 flex flex-col items-center justify-center">
        <p className={`mb-2 font-serif text-white-60 ${StepUp3}`}>
          {t('brand:name')}
        </p>
        <p className={`text-white-60 ${Baseline}`}>{t('brand:memo:short')}</p>
      </footer>
    </main>
  )
}

export default App
