import PropTypes from 'prop-types'
import cx from 'classnames'

import { StepUp1, StepUp2 } from '../../constants'

const Heading = props => {
  const { level, className, children } = props
  const classNames = cx(
    'mb-2',
    level === 3 ? 'mt-7 md:mt-14' : 'mt-4 md:mt-6',
    level === 3 ? StepUp2 : StepUp1,
    'font-bold',
    className,
  )
  const Component = `h${level}`
  return <Component className={classNames}>{children}</Component>
}

Heading.defaultProps = {
  level: 3,
}

Heading.propTypes = {
  level: PropTypes.oneOf([3, 4]).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Heading
