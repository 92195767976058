// eslint-disable-next-line import/no-anonymous-default-export
export default `
### Sobre mí

Mi nombre es María Giner Samper y soy psicóloga colegiada con número CV16208. Estudié psicología en la [Universitat Oberta de Catalunya (UOC)](https://www.uoc.edu/portal/en/index.html) y desde que acabé la carrera universitaria he continuado formándome con la finalidad de ampliar la mirada y así, proporcionar una atención integral en el ámbito de la psicología.

De este recorrido adicional destaco la especialización en acompañamiento en procesos de pérdida y duelo en el [Instituto IPIR](http://www.ipirduelo.com/), la especialización en clínica psicoanalítica, mediante el Máster Internacional en Salud Mental - Clínica Psicoanalítica, que imparte el [Instituto de Altos Estudios Universitarios (IAEU)](https://www.iaeu.edu.es/inicio/), y la formación continuada en la [Escuela Lacaniana de Psicoanálisis (ELP)](https://elp-cvalenciana.org/) de Alicante.

Desde el 2019 colaboro en el departamento de psicología del [Teléfono de la Esperanza](https://telefonodelaesperanza.org/) atendiendo de forma especializada y urgente a personas en situación de crisis. Esta ocupación me permite ir profundizando en la experiencia de acompañar y atender situaciones de toda índole y gravedad.

Integrar los conocimientos adquiridos, por una parte, y la experiencia profesional, por otra, me posibilita proponer un abordaje terapéutico enmarcado dentro de un modelo propio elaborado a partir de distintas metodologías y perspectivas.
`
