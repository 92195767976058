import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import { IntlProvider } from 'react-intl'

import Context from './Context'
import locales, { SUPPORTED_LOCALES, findMatchingLocale } from '../lib/i18n'

const Intl = props => {
  const [locale, setLocale] = useState(findMatchingLocale())

  useEffect(() => {
    const lang = localStorage.getItem('lang')
    if (lang) setLocale(lang)
  }, [])

  const handleChangeLocale = useCallback(
    locale => {
      setLocale(locale)
      localStorage.setItem('lang', locale)
    },
    [setLocale],
  )

  return (
    <Context.Provider
      value={{
        locale,
        locales: SUPPORTED_LOCALES,
        setLocale: handleChangeLocale,
      }}>
      <IntlProvider locale={locale} messages={locales[locale]}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  )
}

Intl.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Intl
