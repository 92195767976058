import PropTypes from 'prop-types'

const Link = props => {
  const { href, children } = props
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (
    <a
      className="hover:text-gray-700 border-b border-current"
      href={href}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  )
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Link
