import PropTypes from 'prop-types'
import cx from 'classnames'

import useTranslations from '../../hooks/useTranslations'
import useSmoothScroll from '../../hooks/useSmoothScroll'

const Logo = props => {
  const { className } = props
  const t = useTranslations()
  const onClick = useSmoothScroll()

  const classNames = cx('items-center', className)

  return (
    <div className={classNames}>
      <a href="#root" onClick={onClick} className="font-serif text-2xl">
        {t('brand:name')}
      </a>
    </div>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
